import * as React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';
import AboutHeader from './components/about/about-header';
import InfenoxDifference from './components/about/infenox-difference';
import SmartBusinessSolution from './components/about/business-solutions';
import LeadershipTeam from './components/about/leadership-team';
import './components/about/styles.scss';

export default function About() {
  return (
    <Layout>
      <Seo title="About Us" />
      <AboutHeader />
      <SmartBusinessSolution />
      <InfenoxDifference />
      <LeadershipTeam />
    </Layout>
  );
}
